<script lang="ts" setup>
import { utils } from 'xlsx';
import Papa from 'papaparse';
import { useAddContainerStore } from '~/store/add_container';
import BulkUploadOptions from '~/components/container/add_container/BulkUploadOptions.vue';
import ManualUploadOptions from '~/components/container/add_container/ManualUploadOptions.vue';
import UploadSubOptions from '~/components/container/add_container/UploadSubOptions.vue';
import { useContainersStore } from '~/store/containers';
import { useBulkUploadStore } from '~/store/bulk_uploads';

const DEFAULT_BULK_BL_UPLOAD_LIMIT = 500;
const { $router } = useNuxtApp();

const addContainer = useAddContainerStore();
const containerStore = useContainersStore();
const bulkStore = useBulkUploadStore();
const snackBar = useSnackbar();
const runtimeConfig = useRuntimeConfig();
const showManualUploadTypes: any = ref(false);
const uploadType: any = ref('');

const closeSideModal = () => {
  if (showManualUploadTypes.value) {
    showManualUploadTypes.value = false;
    return;
  }
  addContainer.setContainerUploadDrawerStatus(false);
  containerStore.setReloadPage();
  uploadType.value = '';
};

const onUploadTypeSelect = (type: string) => {
  uploadType.value = type;
};
const getRowCount = () => {
  const csv: any = utils.sheet_to_csv(bulkStore.worksheet, { blankrows: false, rawNumbers: true });
  return Papa.parse(csv, { quoteChar: '"', escapeChar: '"', skipEmptyLines: true, header: true }).data.length;
};

const onUploadOptionSelect = (type: string) => {
  const uploadLimit = runtimeConfig.public.BULK_UPLOAD_LIMIT
    ? Number(runtimeConfig.public.BULK_UPLOAD_LIMIT)
    : DEFAULT_BULK_BL_UPLOAD_LIMIT;
  console.log('limit', uploadLimit, getRowCount());
  if (uploadType.value === 'bulk' && getRowCount() > uploadLimit && type === UploadTypesEnum.BL.toLowerCase()) {
    snackBar.add({
      text: `File exceeds the ${uploadLimit}-row limit. Please reduce the file size and try again.`,
      type: 'error',
    });
  }
  else {
    $router.push(`/tracking/container/upload/${type}_${uploadType.value}`);
  }
};
</script>

<template>
  <LayoutSideModal
    v-model="addContainer.showContainerUploadDrawer"
    :width="540"
    :title="String($t('containerPage.addContainers'))"
    :on-close="closeSideModal"
  >
    <template v-if="!uploadType">
      <BulkUploadOptions @upload-type-select="onUploadTypeSelect" />
      <ManualUploadOptions @upload-type-select="onUploadTypeSelect" />
    </template>
    <template v-else>
      <UploadSubOptions
        :upload-type="uploadType"
        @upload-option-select="onUploadOptionSelect"
      />
    </template>
  </LayoutSideModal>
</template>

<style lang="scss">
.upload-block {
  border: 0.5px solid;
  gap: 23px;
  border-radius: 6px;
}
</style>
