<script lang="ts" setup>
defineProps({
  uploadType: { required: true, type: String, default: '' },
});
const emit = defineEmits(['uploadOptionSelect']);

const emitOptions = (value: string) => {
  emit('uploadOptionSelect', value);
};
</script>

<template>
  <div class="pt-3">
    <p class="text-primary-1 heading-desktop-5 pb-0 mb-4">
      {{ $t('containerPage.addContainerVia', { uploadType }) }}
    </p>
    <div>
      <div
        class="view-filter-list d-flex pa-5 mb-4 align-center bg-gray-5 border-gray-3 cursor-pointer rounded-lg"
        @click="emitOptions('container')"
      >
        <span class="text-desktop-body-large text-black-2"> {{ $t('common.containerNumber') }}</span>
      </div>
      <div
        class="view-filter-list d-flex pa-5 mb-4 align-center bg-gray-5 border-gray-3 cursor-pointer rounded-lg"
        @click="emitOptions('bl')"
      >
        <span class="text-desktop-body-large text-black-2"> {{ $t('containerPage.blOrBn') }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

</style>
