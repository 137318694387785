export const useOrgAccessCheck = () => {
  const unleashStore: any = useUnleashStore();
  const orgStore: any = useOrgStore();
  const isControlTowerDashboardEnabled = computed(() => true);
  /* eslint-disable max-len */
  const isHistoricalTransitTimeEnabled = computed(() => orgStore?.org?.is_historical_transit_time_enabled || unleashStore?.devTool);
  const isExceptionDashboardEnabled = computed(() => orgStore?.org?.is_exception_dashboard_enabled || unleashStore?.devTool);
  const isCo2ReportDashboardEnabled = computed(() => orgStore?.org?.is_co2_report_dashboard_enabled || unleashStore?.devTool);
  const isAccuracyDashboardEnabled = computed(() => orgStore?.org?.is_accuracy_dashboard_enabled || unleashStore?.devTool);
  const isLatencyAccuracyDashboardEnabled = computed(() => orgStore?.org?.is_latency_accuracy_dashboard_enabled || unleashStore?.devTool);
  const isPortCongestionEnabled = computed(() => orgStore?.org?.is_port_congestion_enabled || unleashStore?.devTool);
  const isAirCargoEnabled = computed(() => orgStore?.org?.is_air_cargo_enabled || unleashStore?.devTool);
  const isAirCargoMapEnabled = computed(() => orgStore?.org?.is_air_cargo_map_enabled || unleashStore?.devTool);
  const isReportModuleEnabled = computed(() => orgStore?.org?.is_report_module_enabled || unleashStore?.devTool);
  const isVesselModuleEnabled = computed(() => orgStore?.org?.is_vessel_module_enabled || unleashStore?.devTool);

  const isContainerModuleEnabled = computed(() => true);
  const isFreightAuditModuleEnabled = computed(() => unleashStore?.devTool);
  const isSailingSchedulesModuleEnabled = computed(() => unleashStore?.devTool);

  const isCo2EmissionsEnabled = computed(() => orgStore?.org?.is_co2_emissions_enable || unleashStore?.devTool);
  const isPetaEnabled = computed(() => orgStore?.org?.is_peta_enabled || unleashStore?.devTool);
  const devTool = computed(() => unleashStore?.devTool || false);
  const checkPageAccess = async (page: string) => {
    const pageAccess: any = {
      reports: isReportModuleEnabled.value,
    };
    const isPageAccess = pageAccess[page];
    if (isPageAccess) {
      return true;
    }
    else {
      location.href = '/404';
    }
  };
  return {
    isControlTowerDashboardEnabled,
    isHistoricalTransitTimeEnabled,
    isExceptionDashboardEnabled,
    isCo2ReportDashboardEnabled,
    isAccuracyDashboardEnabled,
    isLatencyAccuracyDashboardEnabled,
    isPortCongestionEnabled,
    isAirCargoEnabled,
    isAirCargoMapEnabled,
    isReportModuleEnabled,
    isVesselModuleEnabled,
    isContainerModuleEnabled,
    isFreightAuditModuleEnabled,
    isSailingSchedulesModuleEnabled,
    isCo2EmissionsEnabled,
    isPetaEnabled,
    checkPageAccess,
    devTool,
  };
  /* eslint-enable max-len */
};
