<script lang="ts" setup>
import { useBulkUploadStore } from '~/store/bulk_uploads';
import { FileUploadIcon } from '~/utils/svg.util';
import { jsonToCsvDownload } from '~/utils/file.util';

const emit = defineEmits(['uploadTypeSelect']);

const uploader: any = ref(null);

const bulkUploadStore = useBulkUploadStore();
const onUpload = async (event: any) => {
  if (
    event
    && event.target
    && event.target.files
    && event.target.files.length > 0
  ) {
    const file = event.target.files[0];
    const fileBuffer = await file.arrayBuffer();
    bulkUploadStore.setFileData({ fileBuffer, fileName: file.name });
    emit('uploadTypeSelect', 'bulk');
  }
};
const onClick = () => {
  uploader.value.click();
};
const onDrop = () => {};

const downloadSampleFile = () => {
  const sample: Array<any> = [
    {
      '': 1,
      'carrier no': 'ABCD',
      'bill of lading no.': 'ABCD123456789',
      'container no.': 'ABCD1234567',
    },
    {
      '': 2,
      'carrier no': 'WXYZ',
      'bill of lading no.': 'WXYZ123456789',
      'container no.': 'WXYZ1234567',
    },
    {
      '': 3,
      'carrier no': 'PQRS',
      'bill of lading no.': 'PQRS123456789',
      'container no.': 'PQRS1234567',
    },
  ];
  jsonToCsvDownload('container_sample.csv', sample);
};
</script>

<template>
  <div>
    <p class="heading-desktop-5 pb-0 mb-4">
      {{ $t("v2.bulkUpload.containerBulkUpload") }}
    </p>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept=".csv, .xls, .xlsx"
      data-testid="container-page-bulk-upload-file-input"
      @change="onUpload"
    >
    <div
      class="upload-block d-flex cursor-pointer align-center px-6 py-11  bg-other-3 border-gray-2"
      @dragover.prevent
      @drop.prevent="onDrop"
      @click="onClick"
    >
      <FileUploadIcon class="upload-icon" />

      <div class="upload-content">
        <p class="text-black-2 heading-desktop-5 pb-0 mb-2">
          {{ $t("v2.bulkUpload.bulkUploadText") }}
        </p>
        <p class="text-black-2 text-desktop-body-large pb-0 mb-1">
          {{ $t("v2.bulkUpload.uploadSupportFile") }}
        </p>
      </div>
    </div>
    <p
      class="cursor-pointer text-primary-1 text-desktop-body-large pt-3 pb-0 mb-4 text-center text-decoration-underline"
      @click="downloadSampleFile"
    >
      {{ $t("v2.bulkUpload.downloadSampleUploadFile") }}
    </p>
  </div>
</template>

<style lang="scss">

</style>
