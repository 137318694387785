<script setup>
import { useI18n } from 'vue-i18n';
import {
  TrackingIcon,
  ProfileIcon, PortcastIconSmall,
  SupportIcon,
  DocumentIcon,
  ShipIcon,
  DevToolsIcon,
  GlobeIcon,
  // UsDollarIcon,
  DashboardIcon,
} from '~/utils/svg.util';
import AddNewContainer from '~/components/container/add_container/AddNewContainer.vue';
import { useAddContainerStore } from '~/store/add_container';

const props = defineProps(['userName']);
const { $cognito } = useNuxtApp();
const userStore = useUserStore();
const orgStore = useOrgStore();
const { t } = useI18n();
const signOut = (_) => {
  $cognito.unAuthenticateUser(userStore.user.email);
  window.location.href = '/login';
};
const isMasterOrg = computed(() => orgStore.org?.is_master);
const {
  isControlTowerDashboardEnabled,
  isHistoricalTransitTimeEnabled,
  isExceptionDashboardEnabled,
  isCo2ReportDashboardEnabled,
  isAccuracyDashboardEnabled,
  isLatencyAccuracyDashboardEnabled,
  isPortCongestionEnabled,
  isAirCargoEnabled,
  isReportModuleEnabled,
  isVesselModuleEnabled,
  isContainerModuleEnabled,
  // isFreightAuditModuleEnabled,
  isSailingSchedulesModuleEnabled,
  devTool,
} = useOrgAccessCheck();
const addContainerStore = useAddContainerStore();
const sideNavItems = computed(() => ([
  {
    title: t('dashboardPage.analytics'),
    icon: DashboardIcon,
    isActive: false,
    value: 'analytics',
    items: [
      { title: t('appBarTitle.control_tower'), value: 'control_tower', isEnabled: isControlTowerDashboardEnabled.value },
      { title: 'Risk Map', value: 'risk_map', isEnabled: false },
      { title: t('appBarTitle.transit_time'), value: 'transit_time', isEnabled: isHistoricalTransitTimeEnabled.value },
      { title: t('appBarTitle.exceptions'), value: 'exceptions', isEnabled: isExceptionDashboardEnabled.value },
      { title: t('appBarTitle.co2_report'), value: 'co2_report', isEnabled: isCo2ReportDashboardEnabled.value },
      { title: t('appBarTitle.accuracy'), value: 'accuracy', isEnabled: isAccuracyDashboardEnabled.value },
      { title: t('appBarTitle.latency_accuracy'), value: 'latency_accuracy', isEnabled: isLatencyAccuracyDashboardEnabled.value },
      { title: t('appBarTitle.sailing_schedule_dashboard'), value: 'sailing_schedule_dashboard', isEnabled: devTool.value },
    ],
  },
  {
    title: t('appBarTitle.tracking'),
    value: 'tracking',
    isActive: false,
    icon: TrackingIcon,
    items: [
      { title: t('common.container'), value: 'container', isEnabled: isContainerModuleEnabled.value },
      { title: t('common.vessel'), value: 'vessel', isEnabled: isVesselModuleEnabled.value },
      // { title: t('appBarTitle.air_cargo'), notAChildRoute: true, value: 'analytics/air_cargo' },
      { title: t('appBarTitle.air_cargo'), value: 'air_visibility', isEnabled: isAirCargoEnabled.value },

    ],
  },
  // {
  //   title: t('appBarTitle.freightAuditList'),
  //   icon: UsDollarIcon,
  //   value: 'freight_audit',
  //   isActive: false,
  //   isEnabled: isFreightAuditModuleEnabled.value,
  //   items: [
  //     {
  //       title: t('appBarTitle.invoices'), value: 'invoices',
  //       isEnabled: isFreightAuditModuleEnabled.value,
  //     },
  //     {
  //       title: t('appBarTitle.disputeAnalysis'), value: 'dispute_analysis',
  //       isEnabled: isFreightAuditModuleEnabled.value,
  //     },
  //     {
  //       title: t('common.emptyReturn'), value: 'empty_return',
  //       isEnabled: isFreightAuditModuleEnabled.value,
  //     },
  //   ],
  // },
  {
    title: t('appBarTitle.portcastInvoice'),
    value: 'portcast_indices',
    isActive: false,
    icon: GlobeIcon,
    isEnabled: isPortCongestionEnabled.value,
    items: [
      { title: t('appBarTitle.port_congestion'), notAChildRoute: true, value: 'analytics/port_congestion' },
      { title: t('appBarTitle.global_transit_time'), notAChildRoute: true, value: 'analytics/global_transit_time', isEnabled: devTool.value },
    ],
  },
  {
    title: t('v2.sideNav.developerTools'),
    value: 'dev_tools',
    isActive: false,
    icon: DevToolsIcon,
    items: [
      { title: t('v2.sideNav.containerLogs'), value: 'container_logs' },
      { title: t('appBarTitle.lookup_carrier'), value: 'lookup/carrier' },
      { title: t('appBarTitle.lookup_vessel'), value: 'lookup/vessel' },
      { title: t('appBarTitle.portLookup'), value: 'lookup/port' },
      {
        title: t('v2.sideNav.apiDocs'), value: 'api_docs', onClick: () => {
          window.open('https://portcast.stoplight.io', '_blank');
        },
      },
    ],
  },
  {
    title: t('appBarTitle.sailingSchedules'),
    icon: ShipIcon,
    isActive: false,
    value: 'sailing_schedules',
    isEnabled: isSailingSchedulesModuleEnabled.value,
  },
  {
    title: t('appBarTitle.reports'),
    icon: DocumentIcon,
    isActive: false,
    value: 'reports',
    isEnabled: isReportModuleEnabled.value,
  },
  {
    title: t('v2.sideNav.helpCenter'),
    icon: SupportIcon,
    isActive: false,
    value: 'support',
    items: [
      {
        title: t('v2.sideNav.knowledgeBase'), value: 'knowledgeBase', onClick: () => {
          window.open('https://portcast.notion.site/Help-Center-8c0dbe68d173434dbfca94995a194f95', '_blank');
        },
      },
      {
        title: t('appBarTitle.contactSupport'), value: 'reportAnIssue', onClick: () => {
          window.open('mailto:support@portcast.io?subject=I%20need%20help%20with%20Portcast!&body=Please%20let%20us%20know%20your%20issue%20here!');
        },
      },
    ],
  },
  {
    title: props.userName || t('appBarTitle.settings'),
    icon: ProfileIcon,
    isActive: false,
    value: 'settings',
    items: [
      { title: t('appBarTitle.orgSettings'), value: 'org' },
      { title: t('common.logout'), value: 'logout', onClick: () => signOut({ callbackUrl: '/login' }) },
    ],
  },
  // {
  //   title: 'Components',
  //   icon: 'mdi-code-greater-than-or-equal',
  //   isActive: false,
  //   value: 'uikit',
  //   items: [
  //     { title: 'Buttons', value: 'buttons' },
  //     { title: 'Tables', value: 'tables' },
  //   ],
  // },
]));

const openedGroups = ref([]);
onMounted(() => {
  openedGroups.value = [];
});

const onRail = (event) => {
  if (event) {
    openedGroups.value = [];
  }
};

const route = useRoute();

const isActiveGroup = (groupTitle) => {
  const groupItem = sideNavItems.value.find(item => item.value === groupTitle);
  return groupItem && route.path.includes(groupItem.value);
};
const openContainerUploadDrawer = () => {
  addContainerStore.setContainerUploadDrawerStatus(true);
};
</script>

<template>
  <KeepAlive>
    <ClientOnly>
      <v-navigation-drawer
        rail-width="87"
        width="260"
        expand-on-hover
        rail
        class="app-side-nav"
        @update:rail="onRail"
      >
        <v-list>
          <v-list-item
            class="app-side-nav-logo sidenav-add-containers mt-3 mb-7"
            :prepend-icon="PortcastIconSmall"
            :title="t('common.portcast')"
            to="/tracking/container"
          />
          <v-list-item style="display: none">
            <AddNewContainer />
          </v-list-item>
        </v-list>
        <v-list
          v-model:opened="openedGroups"
          class="side-menu-list-items"
        >
          <v-list-item
            v-if="!isMasterOrg"
            class="sidenav-add-containers cursor-pointer mb-7"
            prepend-icon="mdi-plus"
            :title="t('v2.sideNav.addContainers')"
            @click="openContainerUploadDrawer"
          />
          <template v-for="(parentItem, index) in sideNavItems">
            <v-list-group
              v-if="parentItem.items"
              :key="index"
              :value="parentItem.title"
              :is-active="isActiveGroup(parentItem.value)"
              :color="'primary'"

              :class="isActiveGroup(parentItem.value) ? 'text-primary' : ''"
            >
              <template #activator="{ props }">
                <v-list-item
                  class="child-list-item"
                  :prepend-icon="parentItem.icon"
                  v-bind="props"
                  :title="parentItem.title"
                  :value="parentItem.value"
                />
              </template>

              <template
                v-for="(item) in parentItem.items"
                :key="item.value"
              >
                <v-list-item
                  v-if="item.onClick && item.isEnabled!==false"
                  :title="item.title"
                  :value="item.value"
                  class="text-black child-list-item"
                  @click="item.onClick"
                />
                <template v-else>
                  <v-list-item
                    v-if="item.isEnabled!==false"
                    :title="item.title"
                    :value="item.value"
                    :to="`/${item.notAChildRoute ? item.value : `${parentItem.value}/${item.value}`}`"
                    class="text-black child-list-item"
                  />
                </template>
              </template>
            </v-list-group>
            <template v-else>
              <v-list-item
                v-if="parentItem.isEnabled!==false"
                :key="parentItem.title"
                class="parent-with-no-children"
                :prepend-icon="parentItem.icon"
                :title="parentItem.title"
                :value="parentItem.value"
                :is-active="parentItem.isActive"
                :to="`/${parentItem.value}`"
              />
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>
    </ClientOnly>
  </KeepAlive>
</template>

<style lang="scss">
.v-navigation-drawer {
  border-right: 1px solid;
  @extend .border-gray-4;
}

.v-navigation-drawer--temporary {
  box-shadow: none !important;
  border-color: #f6f6f6;
}

.app-side-nav {
  .v-list-group__items {
    display: none;
  }

  .side-menu-list-items {
    .v-list-item {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .v-list-group {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    svg.nuxt-icon {
      font-size: 20px !important;
    }
  }

  &.v-navigation-drawer--is-hovering {
    .v-list-group__items {
      display: block;
    }
  }

  .v-list {
    padding: 0 !important;

    .v-list-item {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .v-list-item__prepend {
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    height: 56px;
    width: 56px;

    .mdi.v-icon, .v-icon {
      font-size: 24px !important;
      opacity: 1;
    }
  }

  .v-list-item__content {
    padding-left: 16px;

    .v-list-item-title {
      min-width: 180px;
      @extend .heading-desktop-5;
      @extend .text-black-1;
    }
  }

  .v-list-item__append {
    width: 20px;
  }

  .v-list-group[is-active=true] {
    .v-list-item__prepend {
      background-color: #edfaff;

      svg.nuxt-icon--fill * {
        fill: #1C7599 !important;
      }
    }
  }

  .app-side-nav-logo {
    .v-icon {
      margin-left: 3px;
      margin-top: 1px;
      font-size: 20px !important;

      svg.nuxt-icon {
        font-size: 24px !important;
      }
    }

    .v-list-item-title {
      @extend .heading-desktop-5 !optional;
      font-size: 20px !important;
      @extend .text-primary-1 !optional;
    }

    .v-list-item__prepend {
      padding-left: 15px !important;
    }

    .v-list-item__overlay {
      background: #fff !important;
    }
  }

  .v-list-group__items {
    .v-list-item {
      padding-right: 0;

    }

    .v-list-item-title {
      font-size: 16px !important;
      font-weight: normal !important;
      background: #fff;
      padding-left: 16px;
      color: #5f747d !important;
    }

    .v-list-item--active {
      .v-list-item__content {
        height: 100%;
        display: flex;
        align-items: center;
        background: #edfaff;
      }

      .v-list-item__overlay {
        background: #fff;
      }

      .v-list-item-title {
        color: #1c7599 !important;
        background: none;

      }
    }
  }

  .parent-with-no-children {
    .v-list-item__overlay {
      background: #fff;
    }

    &.v-list-item--active {
      .v-list-item__prepend {
        background-color: #edfaff;

        i:before {
          color: #1c7599;
        }
      }
    }
  }

  .sidenav-add-containers {
    .v-list-item__prepend {
      background-color: #ececec;
    }
  }
}

//.app-side-nav-logo {
//  .v-list-item-title {
//    @extend .heading-desktop-5 !optional;
//    @extend .text-primary-1 !optional;
//    font-size: 20px !important;
//  }
//}
</style>
