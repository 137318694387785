<script lang="ts" setup>
import { WarningIcon } from '~/utils/svg.util';
import { useOrgStore } from '~/store/org';
import { useUserStore } from '~/store/user';
import AddContainersSideModal from '~/components/container/add_container/AddContainersSideModal.vue';
import { useAddContainerStore } from '~/store/add_container';

const { t } = useI18n();

const orgStore: any = useOrgStore();
const userStore: any = useUserStore();
const addContainerStore = useAddContainerStore();

const ORG_QUOTA_MINIMUM_WARNING = 5;

const showCreditMinimumWarningDialog: any = ref(false);

const getOrgQuota = () => {
  if (isNaN(+orgStore.remainingQuota)) {
    // new user, never uploaded before, use quota instead
    return orgStore.org?.quota;
  }
  return orgStore.remainingQuota?.remaining_api_credits || 0;
};

const openContainerUploadDrawer = () => {
  showCreditMinimumWarningDialog.value = false;
  addContainerStore.setContainerUploadDrawerStatus(true);
};
//
// const preOpenContainerUploadDrawer = () => {
//   const orgQuota = getOrgQuota();
//   if (orgQuota > ORG_QUOTA_MINIMUM_WARNING) {
//     openContainerUploadDrawer();
//   }
//   else {
//     showCreditMinimumWarningDialog.value = true;
//   }
// };

const isOrgQuotaEmpty = () => {
  return getOrgQuota() === 0;
};

const getOrgQuotaWarningHue = () => {
  if (isOrgQuotaEmpty()) {
    return 'text-red-1';
  }
  return 'text-yellow-1';
};

const getOrgQuotaWarning = () => {
  const orgQuota = getOrgQuota();
  if (orgQuota > ORG_QUOTA_MINIMUM_WARNING) {
    return '';
  }
  return String(t('v2.sideNav.addContainersSubtitle', { count: orgQuota }));
};

const getQuotaDialogText = () => {
  if (isOrgQuotaEmpty()) {
    return t('v2.sideNav.creditZeroErrorModalText');
  }
  return t('v2.sideNav.creditMinimumWarningModalText');
};
</script>

<template>
  <div>
    <AddContainersSideModal
      v-if="userStore.cognitoSession && !orgStore.isMasterOrg"
    />
    <v-dialog
      v-model="showCreditMinimumWarningDialog"
      max-width="534px"
    >
      <div class="credit-warning-dialog text-desktop-body-large">
        <div
          :class="`credit-warning-dialog-top ${getOrgQuotaWarningHue()}`"
        >
          <span>
            <WarningIcon />
          </span>
          <span>
            {{ getOrgQuotaWarning() }}
          </span>
        </div>
        <div class="credit-warning-dialog-bottom">
          <div>
            {{ getQuotaDialogText() }}
          </div>
          <div
            v-if="isOrgQuotaEmpty()"
            class="credit-warning-dialog-bottom-button-bar"
          >
            <a
              href="https://www.portcast.io/contact"
              target="_blank"
            >
              <v-btn
                style="background-color: white; width: 170px;"
                :depressed="true"
                color="primary"
                label="Contact Us"
              />
            </a>
          </div>
          <div
            v-else
            class="credit-warning-dialog-bottom-button-bar"
          >
            <a href="https://www.portcast.io/contact">
              <v-btn
                style="background-color: white; width: 170px;"
                :depressed="true"
                label="Contact Us"
              />
            </a>
            <v-btn
              style="width: 170px;"
              :depressed="true"
              color="primary"
              label="Add Container"
              @click="openContainerUploadDrawer"
            />
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<style lang="scss">

</style>
