<script lang="ts" setup>
import { FileListIcon } from '~/utils/svg.util';

const emit = defineEmits(['uploadTypeSelect']);
</script>

<template>
  <div
    class="pt-12"
  >
    <p class="heading-desktop-5 pb-0 mb-4">
      {{ $t("v2.bulkUpload.containerManuallyUpload") }}
    </p>
    <div
      class="upload-block d-flex align-center px-6 py-11 cursor-pointer bg-other-3 border-gray-2"
      @click="emit('uploadTypeSelect', 'manual')"
    >
      <FileListIcon class="upload-icon" />
      <div class="upload-content">
        <p class="text-black-2 heading-desktop-5 pb-0 mb-2">
          {{ $t("v2.bulkUpload.addManuallyContainers") }}
        </p>
        <p class="text-desktop-body-large pb-0 mb-1">
          {{ $t("v2.bulkUpload.addingManuallyTakeMoreTime") }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

</style>
